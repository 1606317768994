@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap')
@import './Components/Styles/Text'
@import './Components/Styles/Colors'
@import './Components/Styles/Constants'

@font-face
    font-family: 'acherusgrotesque-regular'
    src: url('acherusgrotesque-regular-webfont.woff2') format('woff2'), url('acherusgrotesque-regular-webfont.woff') format('woff')
    font-weight: normal
    font-style: normal


body
  margin: 0
  font-family: 'Roboto Condensed', 'acherusgrotesque-regular', 'Roboto', 'Helvetica', 'Times New Roman'
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale


code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace


ul
  list-style: none
  padding: 0

li
  list-style: none
  padding: 0
  margin: 0

a
  text-decoration: none
  display: inline-block
  padding: 0
  margin: 0


.fs1,
.fs2,
.fs3,
.fs4
  color: $fc3

@media screen and (min-width: $ss8min)
  .fs1
    font-size: $ss8-fs1
  .fs2
    font-size: $ss8-fs2
  .fs3
    font-size: $ss8-fs3
  .fs4
    font-size: $ss8-fs4

@media screen and (max-width: $ss7)
  .fs1
    font-size: $ss7-fs1
  .fs2
    font-size: $ss7-fs2
  .fs3
    font-size: $ss7-fs3
  .fs4
    font-size: $ss7-fs4

@media screen and (max-width: $ss6)
  .fs1
    font-size: $ss6-fs1
  .fs2
    font-size: $ss6-fs2
  .fs3
    font-size: $ss6-fs3
  .fs4
    font-size: $ss6-fs4
@media screen and (max-width: $ss5)
  .fs1
    font-size: $ss5-fs1
  .fs2
    font-size: $ss5-fs2
  .fs3
    font-size: $ss5-fs3
  .fs4
    font-size: $ss5-fs4
@media screen and (max-width: $ss4)
  .fs1
    font-size: $ss4-fs1
  .fs2
    font-size: $ss4-fs2
  .fs3
    font-size: $ss4-fs3
  .fs4
    font-size: $ss4-fs4

@media screen and (max-width: $ss3)
  .fs1
    font-size: $ss3-fs1
  .fs2
    font-size: $ss3-fs2
  .fs3
    font-size: $ss3-fs3
  .fs4
    font-size: $ss3-fs4

@media screen and (max-width: $ss2)
  .fs1
    font-size: $ss2-fs1
  .fs2
    font-size: $ss2-fs2
  .fs3
    font-size: $ss2-fs3
  .fs4
    font-size: $ss2-fs4

@media screen and (max-width: $ss1)
  .fs1
    font-size: $ss1-fs1
  .fs2
    font-size: $ss1-fs2
  .fs3
    font-size: $ss1-fs3
  .fs4
    font-size: $ss1-fs4














/**/
