$ss1-fs1: 10px
$ss1-fs2: 14px
$ss1-fs3: $ss1-fs1*2
$ss1-fs4: $ss1-fs2*2

$ss2-fs1: 12px
$ss2-fs2: 17px
$ss2-fs3: $ss2-fs1*2
$ss2-fs4: $ss2-fs2*2

$ss3-fs1: 13px
$ss3-fs2: 20px
$ss3-fs3: $ss3-fs1*2
$ss3-fs4: $ss3-fs2*2

$ss4-fs1: 14px
$ss4-fs2: 23px
$ss4-fs3: $ss4-fs1*2
$ss4-fs4: $ss4-fs2*2

$ss5-fs1: 15px
$ss5-fs2: 25px
$ss5-fs3: $ss5-fs1*2
$ss5-fs4: $ss5-fs2*2

$ss6-fs1: 20px
$ss6-fs2: 33px
$ss6-fs3: $ss6-fs1*2
$ss6-fs3: 40px
$ss6-fs4: 67px

$ss7-fs1: 25px
$ss7-fs2: 42px
$ss7-fs3: $ss7-fs1*2
$ss7-fs3: 50px
$ss7-fs4: 83px

$ss8-fs1: $ss5-fs1*2
$ss8-fs2: $ss5-fs2*2
$ss8-fs3: $ss5-fs3*2
$ss8-fs4: $ss5-fs4*2
