/* PADDING */
$p1: 60px
$p2: 75px
$p3: 112px
$p4: 150px

/* TRANSITION */
$t: 200ms

/* LINE HEIGHT */
$lh1: 24px
$lh2: 36px
$lh3: 45px
$lh4: 72px

/* SCREEN SIZE BREAKPOINTS, max height per each, except $Xmin */
$ss1: 400px
$ss2: 768px
$ss3min: 769px
$ss3: 1366px
$ss4min: 1367px
$ss4: 1680px
$ss5: 1920px
$ss6min: 1921px
$ss6: 2560px
$ss7min: 2561px
$ss7: 3200px
$ss8min: 3201px
$ss8: 3840px
