$fc1:rgb(255, 255, 255)
// button text color
$fc2: #FFFFFF
// text in cards color
$fc3:rgb(233, 231, 236)
// highlight color on hover
$fc4: rgb(75, 75, 74)

$fc5: rgb(194, 212, 24)

$bc1: #000000
// card background color
$bc2:rgb(27, 28, 31)
// Work Experince section background color
$bc3: rgb(27, 28, 31)
// header background
$bc4: #141414
//$bc5: rgb(124, 189, 39)
// titlecard color
$bc5:rgb(27, 28, 31)
// misc involve. section background color
$bc6: #141414
